<template>
  <Modal :show-modal="showCaasDownloadModal" @close="toggleCaasDownloadModal">
    <template #header>
      <TitleRegular class="text-center" title="Preparing file" />
      <TextSmall class="text-center mt-2">
        The following document is being prepared and will be sent to your email
        address soon!
      </TextSmall>
    </template>
    <template #body>
      <img
        class="w-[20%] m-auto my-8"
        :src="require(`@/assets/images/caas_download.png`)"
      />
      <TextSmall blue class="py-3 text-center bg-etBlue-10 rounded-sm"
        >Product description - Phone.txt</TextSmall
      >
    </template>
    <template #footer>
      <BtnsWrapperVertical>
        <BtnPrimary title="Ok" @click="toggleCaasDownloadModal" />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import Modal from '@/components/shared/Modal'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const showCaasDownloadModal = computed(
  () => store.state.modals.showCaasDownloadModal
)
const toggleCaasDownloadModal = () =>
  store.commit('modals/toggleCaasDownloadModal')
</script>
