<template>
  <div
    class="snackbar-notification invisible min-w-[250px] mobileLandscape:min-w-[500px] shadow-[0px_2px_5px_#dee8fe] py-[0.7rem] px-6 absolute z-[800] top-[15px] border rounded-sm max-w-[700px] flex right-[15px] border-etBlue-80 bg-white"
    :class="{
      'animate-[fadeInSnackbar_0.5s,_fadeOutSnackbar_0.5s_9.5s] !visible': show
    }"
  >
    <div>
      <TitleSmall :title="title" class="text-sm" />
      <TextSmall>{{ message }}</TextSmall>
      <BtnNormal
        class="mt-2.5"
        light-theme
        :title="$t('label.notification.open_conversation')"
        @click="handleOpenNotification"
      />
    </div>
    <button
      class="absolute top-2.5 right-5 text-etBlack-50 cursor-pointer hover:text-etBlack-80"
      @click="close"
    >
      <i class="fas fa-times" />
    </button>
  </div>
</template>
<script setup>
import BtnNormal from '@/components/shared/btn/BtnNormal'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TitleSmall from '@/components/shared/font/title/TitleSmall'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t
const route = useRoute()
const router = useRouter()

const time = ref(null)
const channelId = ref(null)

const notification = computed(() => store.state.chatNotification.notification)
const showNotification = computed(
  () => store.state.chatNotification.showNotification
)
const customerConversation = computed(
  () => store.state.chat.currentConversation
)
const supplierConversation = computed(
  () => store.state.supplierChat.currentConversation
)

const title = computed(() => notification.value && notification.value.title)
const message = computed(() => notification.value && notification.value.message)
const show = computed(() => showNotification.value)

const closeNotification = () =>
  store.commit('chatNotification/closeNotification')
const setCustomerConversation = (payload) =>
  store.dispatch('chat/setCurrentConversationById', payload)
const setSupplierConversation = (payload) =>
  store.dispatch('supplierChat/setCurrentConversationById', payload)
const startLoader = () => store.dispatch('loading/startLoader')
const stopLoader = () => store.dispatch('loading/stopLoader')

function close() {
  clearTimeout(time.value)
  closeNotification()
}

async function handleOpenNotification() {
  closeNotification()
  startLoader()
  try {
    if ($cookies.get('userType') !== 'supplier') {
      await setCustomerConversation(channelId.value)
    } else {
      await setSupplierConversation(channelId.value)
    }
  } finally {
    if (
      supplierConversation.value.attributes ||
      customerConversation.value.attributes
    ) {
      if (route.name !== 'customerChat' && route.name !== 'supplierChat') {
        if ($cookies.get('userType') !== 'supplier') {
          stopLoader()
          router.push({
            name: 'customerChat',
            query: {
              view: customerConversation.value.attributes.type
            }
          })
        } else {
          stopLoader()
          router.push({
            name: 'supplierChat',
            query: {
              ...route.query,
              view: supplierConversation.value.attributes.type
            }
          })
        }
      } else {
        if ($cookies.get('userType') !== 'supplier') {
          router.replace({
            query: {
              view: customerConversation.value.attributes.type
            }
          })
        } else {
          router.replace({
            query: {
              ...route.query,
              view: supplierConversation.value.attributes.type
            }
          })
        }
      }
    }
    stopLoader()
  }
  channelId.value = null
}

watch(
  () => show.value,
  (newValue) => {
    if (newValue) {
      time.value = setTimeout(() => {
        closeNotification()
      }, 10000)
    } else {
      clearTimeout(time.value)
    }
  }
)

watch(
  () => notification.value,
  () => {
    if (notification.value.channelId) {
      channelId.value = notification.value.channelId
    }
  }
)

onBeforeUnmount(() => {
  stopLoader()
})
</script>
