<template>
  <section>
    <Modal
      :class="{
        '[&>.modal-wrapper]:!max-w-[800px]': modalType === 'supplier_terms'
      }"
      :show-modal="showModal"
      @close="closeModal"
    >
      <template #body>
        <div class="card-content">
          <div v-if="modalType === 'terms'">
            <TitleSmall :title="$t('shared.label.terms_conditions')" />
            <div v-for="(item, index) in generalContent" :key="index">
              <TextSmall
                class="mt-1"
                :class="{ 'font-bold mt-4': item.type === 'header' }"
              >
                <b>{{ item.point }}</b> {{ item.sentence }}
              </TextSmall>
            </div>
          </div>
          <div v-if="modalType === 'marketing'">
            <div v-for="(item, index) in marketingContent" :key="index">
              <TextSmall
                class="mt-1"
                :class="{ 'font-bold mt-4': item.type === 'header' }"
              >
                <b>{{ item.point }}</b> {{ item.sentence }}
              </TextSmall>
            </div>
          </div>
          <div v-if="modalType === 'supplier_terms'">
            <div v-html="supplierContent[1].sentence" />
          </div>
        </div>
      </template>
      <template #footer>
        <BtnsWrapper class="flex justify-center">
          <BtnSecondary
            :title="$t('shared.button.close')"
            @click="closeModal"
          />
        </BtnsWrapper>
      </template>
    </Modal>
  </section>
</template>
<script setup>
import Modal from '@/components/shared/Modal'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TitleSmall from '@/components/shared/font/title/TitleSmall'
import { ref, watch } from 'vue'

const emit = defineEmits(['closeModal'])
const props = defineProps({
  showModal: Boolean,
  modalType: String,
  generalContent: Array,
  marketingContent: Array,
  supplierContent: Array
})

const source = ref(null)
const target = ref(null)
const loading = ref(false)

watch(
  () => props.supplierContent,
  () => {
    if (props.supplierContent[0]) {
      const css = document.createElement('style')
      css.type = 'text/css'
      css.innerText = props.supplierContent[0].sentence.replace(
        /<\/?style.*?>/g,
        ''
      )
      document.body.append(css)
    }
  }
)

function closeModal() {
  emit('closeModal')
  source.value = null
  target.value = null
  loading.value = false
}
</script>
