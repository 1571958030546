<template>
  <Modal :show-modal="showAiIntroModal">
    <template #header>
      <div class="relative">
        <i
          class="fas fa-times absolute -right-3 -top-3 cursor-pointer text-gray-400 hover:text-gray-500"
          @click="toggleAiIntroModal"
        />
      </div>
      <TitleRegular
        class="text-center"
        :title="$t('customer.modal.freemium_accounts.ai.title')"
      />
    </template>
    <template #body>
      <TextRegular class="text-center">{{
        $t('customer.modal.freemium_accounts.ai.text')
      }}</TextRegular>
    </template>
    <template #footer>
      <BtnsWrapper class="flex justify-center">
        <BtnPrimary
          :title="$t('shared.label.book_meeting')"
          @click="hanldeBookMeeting"
        />
      </BtnsWrapper>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/shared/Modal'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import TextRegular from '@/components/shared/font/text/TextRegular'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import i18n from '@/i18n'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const showAiIntroModal = computed(() => store.state.modals.showAiIntroModal)
const toggleAiIntroModal = () => store.commit('modals/toggleAiIntroModal')

function hanldeBookMeeting() {
  window.open(
    'https://meetings.hubspot.com/kasper-bengtsson-jensen/freemium-round-robin',
    '_blank'
  )
}
</script>
