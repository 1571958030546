<template>
  <a
    class="rounded cursor-pointer flex justify-center items-center bg-white p-4 py-5 h-2.5 border border-etBlack-30 shadow hover:bg-etBlue-primary [&>.fas]:hover:text-white"
    @click="setFolderModal"
  >
    <i class="text-etBlue-primary fas fa-folder" />
  </a>
</template>
<script setup>
import { useStore } from 'vuex'

const store = useStore()

const setFolderModal = () => store.dispatch('modals/setFolderModal')
</script>
