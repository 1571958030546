<template>
  <label
    class="inline-flex items-center cursor-pointer"
    :class="{ '!cursor-not-allowed': disabled }"
  >
    <input
      v-model="localModelValue"
      type="checkbox"
      class="sr-only peer"
      :disabled="disabled"
    />
    <div
      class="relative bg-gray-200 peer-focus:outline-none peer-focus:ring-2 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"
      :class="`${sizes[size]} ${themes[color]}`"
    ></div>
    <span
      v-if="label"
      class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"
    >
      {{ label }}
    </span>
  </label>
</template>

<script setup>
import { computed, ref } from 'vue'

const emit = defineEmits(['update:modelValue', 'on', 'off'])
const props = defineProps({
  label: String,
  modelValue: Boolean,
  color: {
    type: String,
    default: 'primary',
    validator(value, props) {
      // The value must match one of these strings
      return ['primary', 'red', 'green', 'etGreen'].includes(value)
    }
  },
  disabled: Boolean,
  size: {
    type: String,
    default: 'md',
    validator(value, props) {
      // The value must match one of these strings
      return ['sm', 'md', 'lg'].includes(value)
    }
  }
})

const defaultColor = '#2c3fd5'

const sizes = ref({
  sm: 'w-9 h-5 after:!w-4 after:!h-4',
  md: 'w-11 h-6',
  lg: 'w-14 h-7'
})

const themes = ref({
  primary:
    'peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:bg-etBlue-primary',
  red: 'peer-focus:ring-red-300 dark:peer-focus:ring-red-800 peer-checked:bg-red-600',
  green:
    'peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:bg-green-600',
  etGreen: 'peer-focus:ring-etGreen-100 peer-checked:bg-etGreen-100'
})

const getColor = computed(() => {
  return props.color || defaultColor
})
const localModelValue = computed({
  get: () => props.modelValue,
  set: (newValue) => {
    emit('update:modelValue', newValue)
    if (newValue) {
      emit('on')
    } else {
      emit('off')
    }
  }
})
</script>
