<template>
  <div
    class="folderCard relative grid grid-rows-[repeat(3,_auto)] grid-cols-[auto] gap-y-2.5 gap-x-0 w-auto h-fit bg-white p-5 rounded-lg text-[#707070] !cursor-pointer shadow [&>.edit]:hidden [&>.edit]:hover:!block"
    :class="{
      '!grid-rows-[auto] !grid-cols-[repeat(auto-fit,_minmax(180px,_1fr))] !justify-self-between !gap-x-5 !gap-y-0':
        showListView
    }"
    data-cy="folder_card"
    @click="onClick"
  >
    <ModalDeleteFolder
      :show="showDeleteModal"
      :folder-name="data.attributes.name"
      :folder-id="data.id"
      @close="closeDeleteModal"
    />
    <ButtonEditCard
      class="edit absolute top-2"
      :class="{
        '!top-1/4 right-[10px]': showListView
      }"
      :data-cy="`folder_${data.attributes.name}_edit_card`"
    >
      <template #option>
        <span @click="openModal(data)">
          {{ $t('shared.button.rename_folder') }}
        </span>
        <span
          class="!text-etRed-100"
          :data-cy="`delete_${data.attributes.name}`"
          @click="openDeleteModal"
        >
          {{ $t('shared.button.delete_folder') }}
        </span>
      </template>
    </ButtonEditCard>
    <router-link
      :to="{ name: 'clientFolder', params: { id: data.id } }"
      class="flex items-center gap-2 capitalize border-b border-etGray-primary cursor-pointer text-base pb-1"
      :class="{ '!border-b-0 !pb-0': showListView }"
    >
      <InlineSvg
        class="h-full [&>g>g>path]:!fill-etBlack-50 [&>g>g>path]:!opacity-100"
        :src="require('@/assets/icons/folder-icon.svg')"
        alt="dot"
        width="20"
      />
      <TitleTiny class="text-etBlack-100" :title="data.attributes.name" />
    </router-link>
    <router-link
      :to="{ name: 'clientFolder', params: { id: data.id } }"
      class="flex gap-2 items-center"
    >
      <font-awesome-icon
        v-if="!showListView"
        icon="fa-clock"
        class="text-xs text-etBlack-50"
      />
      <span
        class="text-etBlack-100 text-xs"
        :class="{ 'bg-etBlue-10 rounded p-1 px-2 font-bold': showListView }"
      >
        {{ createdAt }}</span
      >
    </router-link>
    <router-link
      :to="{ name: 'clientFolder', params: { id: data.id } }"
      class="flex gap-2 items-center"
    >
      <font-awesome-icon
        v-if="!showListView"
        icon="fas fa-copy"
        class="text-xs text-etBlack-50"
      />
      <span
        class="text-etBlack-100 text-xs"
        :class="{ 'bg-etBlue-10 rounded p-1 px-2 font-bold': showListView }"
        >{{
          $t('folders.folder.project_count', {
            count: data.attributes.total_projects
          })
        }}</span
      >
    </router-link>
  </div>
</template>
<script setup>
import ButtonEditCard from '@/components/shared/ButtonEditCard'
import TitleTiny from '@/components/shared/font/title/TitleTiny'
import ModalDeleteFolder from '@/components/shared/modals/ModalDeleteFolder'
import i18n from '@/i18n'
import moment from 'moment'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t
const router = useRouter()

const props = defineProps({
  data: Object
})

const showDeleteModal = ref(false)
const showListView = computed(() => store.state.account.showListView)
const formatDate = computed(() =>
  moment(props.data.attributes.created_at).format('YYYY-MM-DD')
)
const createdAt = computed(() =>
  showListView.value
    ? formatDate.value
    : $t('shared.label.created_on', { date: formatDate.value })
)

const setRenameFolder = (payload) =>
  store.commit('workspace/setRenameFolder', payload)
const toggleRenameModal = (payload) =>
  store.commit('modals/toggleRenameModal', payload)

function onClick(e) {
  if (e.target.classList.contains('folderCard')) {
    router.push({
      name: 'clientFolder',
      params: { id: props.data.id }
    })
  }
}
function openModal(folder) {
  toggleRenameModal(true)
  setRenameFolder({ id: folder.id, name: folder.attributes.name })
}
function openDeleteModal() {
  showDeleteModal.value = true
}
function closeDeleteModal() {
  showDeleteModal.value = false
}
</script>
