<template>
  <Modal :show-modal="showinstructionsModal" @close="toggleInstructionsModal">
    <template #header>
      <TitleRegular
        :title="
          isSupplier
            ? $t('shared.label.specific_instructions')
            : $t('shared.label.specific_instructions.customer')
        "
      />
    </template>
    <template #body>
      <div class="rounded p-4 bg-etBlue-10" v-html="instructions" />
    </template>
    <template #footer>
      <BtnsWrapperVertical>
        <BtnSecondary
          :title="$t('shared.button.cancel')"
          @click="toggleInstructionsModal"
        />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/shared/Modal'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import { $cookies } from '@/main'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const isSupplier = computed(() => {
  return $cookies.get('userType') === 'supplier'
})
const currentProject = computed(() => store.state.project.currentProject)
const currentTask = computed(() => store.state.supplierDashboard.currentTask)
const showinstructionsModal = computed(
  () => store.state.modals.showinstructionsModal
)
const instructions = computed(() => {
  const instruction = isSupplier.value
    ? currentTask.value.attributes.customer_instructions
    : currentProject.value.attributes.customer_instructions
  return instruction.replace(/\n/g, '<br />')
})
const toggleInstructionsModal = () =>
  store.commit('modals/toggleInstructionsModal')
</script>
