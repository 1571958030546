<template>
  <transition name="modal">
    <div
      v-if="showModal"
      class="modal-mask fixed z-[9998] top-0 left-0 w-full h-full bg-[rgba(0,32,63,0.7)] table transition-opacity duration-300 ease-in-out"
      @click="(e) => emit('close', e)"
    >
      <div
        class="modal-wrapper absolute flex max-h-[90%] w-full max-w-[600px] left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-[calc(50%+1px)]"
      >
        <div
          class="modal-container relative w-fit-content w-full mx-auto overflow-y-auto p-4 bg-white rounded-lg shadow-md transition-all duration-300 ease mobileLandscape:p-8"
          @click.stop
        >
          <div class="modal-header">
            <slot name="header" />
          </div>
          <div class="modal-body my-4">
            <slot name="body" />
          </div>
          <div class="modal-footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script setup>
const props = defineProps({
  showModal: Boolean
})
const emit = defineEmits(['close'])
</script>
