<template>
  <div
    class="border border-etGray-primary rounded !text-etBlack-100 hover:border-etBlue-primary focus-visible:border-etBlue-primary visited:border-etBlue-primary"
  >
    <Datepicker
      class="w-full"
      :value="value"
      input-class="field__input"
      :placeholder="placeholder"
      :disabled-dates="disabledDates"
      :monday-first="true"
      :language="$i18n.locale.substring(0, 2)"
      @selected="emit('inputChange', $event)"
    />
  </div>
</template>
<script setup>
import moment from 'moment'
import { computed } from 'vue'
import Datepicker from 'vuejs3-datepicker'

const props = defineProps({
  value: {
    type: Date,
    default: ''
  },
  placeholder: String,
  disableFutureDates: Boolean,
  disablePastDates: Boolean,
  disableTo: String,
  disableFrom: String
})
const emit = defineEmits(['inputChange'])

const disabledDates = computed(() => {
  if (props.disableTo || props.disableFrom) {
    return {
      ...(props.disableTo
        ? {
            to: moment(props.disableTo).startOf('day').add(1, 'd').toDate()
          }
        : {
            to: props.disablePastDates
              ? moment().startOf('day').add(1, 'd').toDate()
              : ''
          }),
      ...(props.disableFrom
        ? {
            from: moment(props.disableFrom).startOf('day').toDate()
          }
        : { from: props.disableFutureDates ? new Date() : '' })
    }
  }
  if (!props.disableFutureDates && !props.disablePastDates) {
    return {}
  }
  return {
    ...(props.disablePastDates
      ? {
          to: moment().startOf('day').add(1, 'd').toDate()
        }
      : {}),
    ...(props.disableFutureDates ? { from: new Date() } : {})
  }
})
</script>
<style lang="scss" scoped>
:deep(.vuejs3-datepicker__calendar-topbar) {
  display: none;
}

:deep(.vuejs3-datepicker__calendar header .up:not(.disabled)) {
  &:hover {
    background: $blue-80 !important;
    color: $white;
  }
}

:deep(.vuejs3-datepicker__value) {
  border: none;
  padding: 9px 15px;
}

:deep(.vuejs3-datepicker__calendar) {
  border-radius: 2.5px;
  .cell {
    border-radius: 0.313rem;
    &:hover {
      border-color: '#2c3fd5' !important;
    }
    &.selected {
      background: '#2c3fd5' !important;
      color: white !important;
    }
  }
}
</style>
