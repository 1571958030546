<template>
  <div
    class="w-full flex justify-center h-11 mt-4"
    :class="{ 'cursor-not-allowed': disabled }"
  >
    <Tooltip
      position="bottom"
      :disabled="!disabled"
      grey-theme
      :description="$t('shared.google_signup.empty_consents')"
    >
      <div
        id="g_id_onload"
        class="w-full items-center flex justify-center"
        :class="{ 'cursor-not-allowed pointer-events-none': disabled }"
        :data-locale="i18n.locale"
      />
    </Tooltip>
  </div>
</template>
<script setup>
import Tooltip from '@/components/shared/tooltip/Tooltip'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const props = defineProps({
  disabled: Boolean,
  signup: Boolean
})
const emit = defineEmits(['success'])

onMounted(() => {
  const handleCredentialResponseSignin = async (credentials) => {
    const crm = {
      context: {
        hutk: $cookies.get('hubspotutk'),
        pageUri: window.location.href,
        pageName: 'Onobarding freemium',
        ipAddress: null
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: 'I agree to EasyTranslate to store and process my personal data.',
          communications: [
            {
              value: true,
              subscriptionTypeId: 11090282,
              text: `EasyTranslate needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our Privacy Policy.`
            }
          ]
        }
      }
    }
    try {
      await authenticateWithGoogle({
        token: credentials.credential,
        crm: crm
      })
      // S360 track google sign ins
      if (isOnProduction.value) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'genericGAEvent',
          eventCategory: 'sign_in',
          eventAction: 'google',
          eventLabel: '',
          nonInteraction: false
        })
      }
      emit('success')
    } catch (err) {
      console.log(err)
    }
  }
  const handleCredentialResponseSignup = async (credentials) => {
    const crm = {
      context: {
        hutk: $cookies.get('hubspotutk'),
        pageUri: window.location.href,
        pageName: 'Onobarding freemium',
        ipAddress: null
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: 'I agree to EasyTranslate to store and process my personal data.',
          communications: [
            {
              value: true,
              subscriptionTypeId: 11090282,
              text: `EasyTranslate needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our Privacy Policy.`
            }
          ]
        }
      }
    }
    try {
      await signUpWithGoogle({ token: credentials.credential, crm: crm })
      // S360 track google sign ins
      if (isOnProduction.value) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'genericGAEvent',
          eventCategory: 'sign_in',
          eventAction: 'google',
          eventLabel: '',
          nonInteraction: false
        })
      }
      await authenticateWithGoogle({
        token: credentials.credential,
        crm: crm
      })
      emit('success')
    } catch (err) {
      console.log(err)
    }
  }
  window.onload = function () {
    const google = window.google

    if (google) {
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: props.signup
          ? handleCredentialResponseSignup
          : handleCredentialResponseSignin,
        context: 'signin',
        use_fedcm_for_prompt: true
      })
      google.accounts.id.renderButton(
        document.getElementById('g_id_onload'),
        { theme: 'outline', size: 'large' } // customization attributes
      ) // This will trigger the button
      google.accounts.id.prompt() // This will trigger the prompt window
    }
  }
})

const isOnProduction = computed(() => store.getters['account/isOnProduction'])
const authenticateWithGoogle = (payload) =>
  store.dispatch('auth/authenticateWithGoogle', payload)
const signUpWithGoogle = (payload) =>
  store.dispatch('auth/signUpWithGoogle', payload)
</script>
