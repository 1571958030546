<template>
  <div
    class="relative grid gap-y-3 grid-rows-[auto,h-min,h-min,h-min,auto] grid-cols-[1fr,1fr] h-auto w-full shadow bg-white p-4 rounded cursor-pointer [&>.edit]:opacity-0 [&>.edit]:hover:!opacity-100"
    :class="{
      '!grid-rows-1 !grid-cols-[repeat(10,_1fr)_20px] gap-x-4 items-center':
        showListView
    }"
    data-cy="project_card"
    @click="onClick"
  >
    <ButtonEditCard
      class="edit top-2"
      :class="{
        '!relative !top-0 right-[10px] self-center	col-start-11 row-start-1 row-end-1':
          showListView
      }"
    >
      <template #option>
        <span @click="showProjectModal(data.id)">{{
          $t('shared.button.move_to_folder')
        }}</span>
      </template>
    </ButtonEditCard>
    <router-link
      data-cy="project_identifier"
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="flex flex-col gap-1 row-span-1 col-span-2"
      :class="{
        'border-b border-etGray-primary pb-3': !showListView
      }"
    >
      <TextSmall
        bold
        :class="{
          'text-ellipsis whitespace-nowrap overflow-hidden max-w-[400px]':
            showListView
        }"
        >{{ data.attributes.identifier }}</TextSmall
      >
      <TextTiny
        class="w-full !text-etBlack-80 tabletPortrait:!text-sm"
        :class="{
          'text-ellipsis whitespace-nowrap overflow-hidden max-w-[400px]':
            showListView
        }"
        >{{ data.attributes.name }}</TextTiny
      >
    </router-link>
    <router-link
      v-if="!showListView"
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="flex flex-col col-span-2"
    >
      <Label :text="`${$t('shared.label.workflow')}:`" />
      <TextTiny
        class="bg-etBlue-10 rounded-sm w-fit py-[1px] px-3 tabletPortrait:!text-sm flex items-center gap-1"
      >
        <span
          ref="service"
          class="w-[10px] h-[10px] block rounded-sm"
          :class="{
            '!bg-etGreen-100': status.theme === 'green',
            '!bg-etRed-100': status.theme === 'red',
            '!bg-etYellow-100': status.theme === 'yellow',
            '!bg-etBlue-primary': status.theme === 'blue',
            '!bg-etBlue-dark': status.theme === 'gray'
          }"
        ></span>
        {{ data.attributes.workflow.attributes.display_name }}
      </TextTiny>
    </router-link>
    <router-link
      v-if="!showListView"
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="flex flex-col"
    >
      <Label :text="`${$t('shared.label.price_without_vat')}:`" />
      <OutputText
        :text="`${formatPrice(
          data.attributes.price.amount,
          data.attributes.price.currency
        )}`"
      />
    </router-link>
    <router-link
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="flex flex-col"
      :class="{ 'row-start-1 col-start-4 col-span-1': showListView }"
    >
      <Label v-if="!showListView" :text="`${$t('shared.label.date')}:`" />
      <OutputText :text="`${formatDate(data.attributes.created_at)}`" />
    </router-link>
    <router-link
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="flex flex-col"
      :class="{ 'row-start-1 col-start-7 col-span-1': showListView }"
    >
      <Label
        v-if="!showListView"
        :text="`${
          data.attributes.workflow.attributes.identifier === 'copyediting'
            ? $t('shared.label.languages')
            : $t('shared.label.from')
        }:`"
      />
      <OutputText
        :text="`${capitalize(
          data.attributes.workflow.attributes.identifier !== 'copyediting'
            ? data.attributes.source_language
            : targetList
        )}`"
      />
    </router-link>
    <router-link
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="flex flex-col"
      :class="{ 'row-start-1 col-start-8 col-span-1': showListView }"
    >
      <Label
        v-if="
          !showListView &&
          data.attributes.workflow.attributes.identifier !== 'copyediting'
        "
        :text="`${$t('shared.label.to')}:`"
      />
      <OutputText
        v-if="data.attributes.workflow.attributes.identifier !== 'copyediting'"
        :text="`${capitalize(targetList)}`"
      />
    </router-link>
    <router-link
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="flex flex-col"
      :class="{ 'row-start-1 col-start-5 col-span-2': showListView }"
    >
      <Label
        v-if="!showListView"
        :text="`${$t('shared.label.preferred_deadline')}:`"
      />
      <OutputText
        :text="`${formatDeadline(data.attributes.preferred_deadline)}`"
      />
    </router-link>
    <router-link
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="col-span-2 w-full"
      :class="{
        'border-t border-etGray-primary pt-3': !showListView
      }"
    >
      <TextSmall
        class="w-full flex items-center justify-center rounded p-1 tabletPortrait:!text-base"
        :class="{
          '!bg-etGreen-20 !text-etGreen-100': status.theme === 'green',
          '!bg-etRed-10 !text-etRed-100': status.theme === 'red',
          '!bg-etYellow-10 !text-etYellow-100': status.theme === 'yellow',
          '!bg-etBlue-10 !text-etBlue-primary': status.theme === 'blue',
          '!bg-etBlue-10 !text-etBlue-dark': status.theme === 'gray'
        }"
        >{{ status.text }}</TextSmall
      >
    </router-link>
  </div>
</template>
<script setup>
import { ProjectService } from '@/api/new/services/project.service'
import ButtonEditCard from '@/components/shared/ButtonEditCard'
import Label from '@/components/shared/font/label/Label'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import OutputText from '@/components/shared/output/OutputText'
import { capitalize } from '@/helpers/format'
import moment from 'moment'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()

const service = ref(null)

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const showListView = computed(() => store.state.account.showListView)

const setProjectId = (payload) =>
  store.commit('workspace/setProjectId', payload)
const toggleSelectFolderModal = () =>
  store.commit('modals/toggleSelectFolderModal')

const status = computed(() => {
  return ProjectService.getStatus(props.data.attributes.status)
})
const theme = computed(() => status.value.theme)

const targetList = computed(() => {
  return props.data.attributes.target_languages.join(', ')
})

function onClick(e) {
  if (e.target.classList.contains('project')) {
    router.push({
      name: 'clientProject',
      params: { id: props.data.id }
    })
  }
}

function showProjectModal(id) {
  toggleSelectFolderModal()
  setProjectId(id)
}

function formatPrice(price, currency) {
  if (price === 0) return '-'
  return `${price / 100} ${currency}`
}

function formatDate(date) {
  if (!date) return '-'
  return moment(date).format('YYYY-MM-DD')
}

function formatDeadline(date) {
  if (!date) return '-'
  return moment(date).format('YYYY-MM-DD HH:mm')
}
</script>
