<template>
  <div
    class="hidden w-full h-full bg-etBlue-light tabletLandscape:!block justify-center items-center"
    :class="{ 'bg-etBlue-primary': darkBg }"
  >
    <LocalLoader :is-medium="true" :loading="loading" />
    <div :class="['w-full h-full', { 'opacity-0': isLoading }]">
      <slot />
    </div>
  </div>
</template>
<script setup>
import LocalLoader from '@/components/shared/loaders/LocalLoader'
import { ref, watch } from 'vue'
const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  darkBg: {
    type: Boolean,
    default: false
  }
})

const isLoading = ref(props.loading)

watch(
  () => props.loading,
  () => {
    isLoading.value = props.loading
  }
)
</script>
