<template>
  <div class="flex">
    <fwb-checkbox
      v-model="toggle"
      @click="onClick"
      data-cy="agreement_option"
    />
    <slot />
  </div>
</template>
<script setup>
import { FwbCheckbox } from 'flowbite-vue'
import { ref, watch } from 'vue'
const emit = defineEmits(['onClick'])
const props = defineProps({
  value: Boolean
})
const toggle = ref(false)
watch(
  () => props.value,
  (newVal) => {
    toggle.value = newVal
  },
  { deep: true, immediate: true }
)

function onClick() {
  toggle.value = !toggle.value
  emit('onClick', toggle.value)
}
</script>
