<template>
  <Modal
    v-if="billing.attributes"
    :show-modal="showExceededVoucherModal"
    class="[&>.modal-wrapper]:max-w-[1000px]"
    @close="handleClose"
  >
    <template #header>
      <div class="text-center">
        <TitleSmall :title="$t('customer.voucher.title_regular')" />
        <TextTiny>{{ $t('customer.voucher.subtitle') }}</TextTiny>
      </div>
    </template>
    <template #body>
      <div class="grid grid-cols-1 desktop:grid-cols-2">
        <PaymentInfo :billing-data="billingData" content="exceededVoucher" />
        <PaymentOverview content="exceededVoucher" />
      </div>
    </template>
  </Modal>
</template>
<script setup>
import PaymentInfo from '@/components/payment/PaymentInfo'
import PaymentOverview from '@/components/payment/PaymentOverview'
import TextTiny from '@/components/shared/font/text/TextTiny'
import TitleSmall from '@/components/shared/font/title/TitleSmall'
import Modal from '@/components/shared/Modal'
import i18n from '@/i18n'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const router = useRouter()
const $t = i18n.t

const showExceededVoucherModal = computed(
  () => store.state.modals.showExceededVoucherModal
)
const billing = computed(() => store.state.payment.billing)
const account = computed(() => store.state.account.account)

const billingData = computed(() => {
  return JSON.parse(JSON.stringify(billing.value))
})
const isFreemium = computed(() => {
  if (account.value.attributes.boolean_flags.is_freemium_voucher_redeemed) {
    return false
  }
  return true
})

const toggleExceededVoucherModal = () =>
  store.commit('modals/toggleExceededVoucherModal')

function handleClose() {
  toggleExceededVoucherModal()
  router.replace({
    query: { ...route.query, exceededVoucher: false }
  })
}
</script>
