<template>
  <Modal :show-modal="showCreateFolderModal" @close="handleClose()">
    <template #body>
      <LocalLoader :is-medium="true" :loading="loading" />
      <InputField
        :placeholder="$t('shared.placeholder.folder_name')"
        data-cy="create_folder_input"
        :red-border="nameAlreadyExists"
        @inputChange="getFolderName"
      />
      <div v-if="nameAlreadyExists" class="text-xs mt-1 text-etRed-100">
        {{ $t('customer.folder.modal.name_exists') }}
        <font-awesome-icon
          icon="fa-solid fa-triangle-exclamation"
          color="red"
        />
      </div>
    </template>
    <template #footer>
      <BtnsWrapperVertical>
        <BtnPrimary
          data-cy="create_folder_button"
          :title="$t('shared.button.create_folder')"
          :disabled="enableButton"
          @click="onCreateNewFolder"
        />
        <BtnSecondary
          :title="$t('shared.button.cancel')"
          @click="handleClose()"
        />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/shared/Modal'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import InputField from '@/components/shared/input/InputField'
import LocalLoader from '@/components/shared/loaders/LocalLoader'
import i18n from '@/i18n'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const name = ref('')
const loading = ref(false)

const showCreateFolderModal = computed(
  () => store.state.modals.showCreateFolderModal
)
const allFolders = computed(() => store.state.folder.allFolders)
const enableButton = computed(
  () => nameAlreadyExists.value || name.value == '' || loading.value
)

const nameAlreadyExists = computed(() => {
  const foundName = allFolders.value.find(
    (obj) => obj.attributes.name.toUpperCase() === name.value.toUpperCase()
  )
  if (foundName) {
    return true
  } else return false
})

function handleClose() {
  toggleCreateFolderModal()
  name.value = ''
}

const createNewFolder = (payload) =>
  store.dispatch('workspace/createNewFolder', payload)
const toggleCreateFolderModal = () =>
  store.commit('modals/toggleCreateFolderModal')

function onCreateNewFolder() {
  loading.value = true
  createNewFolder({ folderName: name.value }).finally(() => {
    toggleCreateFolderModal()
    name.value = ''
    loading.value = false
  })
}
function getFolderName(folder) {
  name.value = folder.value
}
</script>
