<template>
  <Modal :show-modal="showCooperationAgreementModal">
    <template #header>
      <TitleRegular :title="$t('customer.terms_conditions.modal.title')" />
    </template>
    <template #body>
      <TextRegular>
        {{ $t('customer.terms_conditions.modal.text') }}
      </TextRegular>
    </template>
    <template #footer>
      <BtnsWrapper class="flex flex-row justify-end">
        <BtnPrimary
          :title="$t('supplier.cooperation_agreement.modal.btn')"
          @click="handleContinue"
        />
      </BtnsWrapper>
    </template>
  </Modal>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import TextRegular from '@/components/shared/font/text/TextRegular'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import Modal from '@/components/shared/Modal'
import i18n from '@/i18n'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t
const route = useRoute()
const router = useRouter()

const showCooperationAgreementModal = computed(
  () => store.state.modals.showCooperationAgreementModal
)
const setCooperationAgreementModal = (payload) =>
  store.commit('modals/setCooperationAgreementModal', payload)

function handleContinue() {
  setCooperationAgreementModal(false)
  if (route.name !== 'clientPrivacyConsent') {
    router.push({ name: 'clientPrivacyConsent' })
  }
}
</script>
