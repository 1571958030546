<template>
  <div
    v-if="showLoader"
    class="bg-[#f8f9fb69] absolute top-0 left-0 w-screen h-screen z-[3000]"
    @click="clickAway"
  >
    <div class="h-full flex justify-center items-center">
      <fwb-spinner class="!w-20 !h-20 !filter-none fill-etBlue-primary" />
    </div>
  </div>
</template>
<script setup>
import { FwbSpinner } from 'flowbite-vue'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const showLoader = ref(false)

const loading = computed(() => store.state.loading.shyLoading)
const stopLoader = () => store.dispatch('loading/stopLoader')

function clickAway() {
  stopLoader()
  showLoader.value = false
}

watch(
  () => loading.value,
  (value) => {
    showLoader.value = value
  }
)
</script>
