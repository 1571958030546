<template>
  <Modal :show-modal="showCaasIntroModal" @close="emit('cancel')">
    <template #body>
      <video autoplay loop class="w-full">
        <source src="@/assets/images/cg.mp4" type="video/mp4" />
      </video>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/shared/Modal'
import { computed } from 'vue'
import { useStore } from 'vuex'

const emit = defineEmits(['cancel'])
const store = useStore()

const showCaasIntroModal = computed(() => store.state.modals.showCaasIntroModal)
</script>
