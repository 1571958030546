<template>
  <div
    class="grid grid-cols-[repeat(auto-fill,_minmax(20.875rem,_1fr))] auto-rows-auto grid-flow-dense gap-5"
    :class="{ '!grid-cols-1 !gap-y-3': listView }"
    data-cy="projects_grid"
  >
    <div
      v-if="listView"
      class="bg-etBlack-20 grid text-sm grid-cols-[repeat(10,_1fr)_20px] grid-rows-[auto] p-[10px_20px] rounded-md gap-x-5"
    >
      <span class="text-etBlack-80 block col-start-1 col-span-3"
        >{{ projectHeader }}:</span
      >
      <span class="text-etBlack-80 block col-start-4 col-span-1"
        >{{ dateHeader }}:</span
      >
      <span class="text-etBlack-80 block col-start-5 col-span-2"
        >{{ $t('shared.label.preferred_deadline') }}:</span
      >
      <span class="text-etBlack-80 block">{{ $t('shared.label.from') }}:</span>
      <span class="text-etBlack-80 block">{{ $t('shared.label.to') }}:</span>
      <span class="text-etBlack-80 block"
        >{{ $t('shared.label.progress') }}:</span
      >
    </div>
    <slot />
  </div>
</template>
<script setup>
import i18n from '@/i18n'
import { $cookies } from '@/main'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const showListView = computed(() => store.state.account.showListView)
const showSupplierListView = computed(
  () => store.state.account.showSupplierListView
)
const isCustomer = computed(() => $cookies.get('userType') !== 'supplier')

const projectHeader = computed(() =>
  isCustomer.value ? $t('shared.label.project') : $t('shared.label.task')
)

const dateHeader = isCustomer.value
  ? $t('shared.label.date')
  : $t('shared.label.deadline')

const isSupplier = computed(() => $cookies.get('userType') === 'supplier')
const listView = computed(() =>
  isSupplier.value ? showSupplierListView.value : showListView.value
)
</script>
