<template>
  <div
    class="flex items-center text-white bg-etBlue-80 relative w-fit text-xs rounded text-ellipsis overflow-hidden break-words whitespace-break-spaces p-1 pr-[25px]"
    :class="{
      '!bg-etBlue-100': hover,
      '!bg-etBlue-10 !text-etBlack-100 !border !border-etBlue-80': light,
      '!bg-etBlue-20 !text-etBlack-100': light && hover,
      '!cursor-not-allowed !pr-[5px]': disabled
    }"
  >
    <slot />
    <button
      v-if="!disabled"
      data-cy="remove_filter_item"
      class="remove flex justify-center items-center absolute h-fit right-1 top-0.5 font-bold cursor-pointer"
      :class="{ '!text-etBlack-50': light }"
      @click="$emit('remove')"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <i class="fas fa-times" />
    </button>
  </div>
</template>
<script setup>
import { ref } from 'vue'
const emit = defineEmits(['remove'])
const props = defineProps({
  light: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const hover = ref(false)
</script>
