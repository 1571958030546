<template>
  <Modal :show-modal="show" @close="closeModal">
    <template #header>
      <TitleRegular :title="$t('folders.remove.title')" />
    </template>
    <template #body>
      <LocalLoader :is-medium="true" :loading="loading" />
      <TextRegular>
        <i18n-t keypath="folders.remove.confirm" tag="p">
          <template #folderName>
            <b>{{ folderName }}</b>
          </template>
        </i18n-t>
        {{ $t('folders.remove.warning_text') }}
      </TextRegular>
    </template>
    <template #footer>
      <BtnsWrapper>
        <BtnDanger
          :title="$t('shared.button.delete')"
          data-cy="delete_folder"
          @click="handleDelete"
        />
        <BtnSecondary :title="$t('shared.button.cancel')" @click="closeModal" />
      </BtnsWrapper>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/shared/Modal'
import BtnDanger from '@/components/shared/btn/BtnDanger'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import TextRegular from '@/components/shared/font/text/TextRegular'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import LocalLoader from '@/components/shared/loaders/LocalLoader'
import i18n from '@/i18n'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const emit = defineEmits(['close'])
const props = defineProps({
  folderName: String,
  folderId: String,
  show: Boolean
})

const loading = computed(() => store.state.loading.loading)

const deleteFolder = (payload) =>
  store.dispatch('workspace/deleteFolder', payload)

function closeModal() {
  emit('close')
}
async function handleDelete() {
  try {
    await deleteFolder(props.folderId)
  } finally {
    closeModal()
  }
}
</script>
