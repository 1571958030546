<template>
  <Modal :show-modal="showVerifyMfaModal">
    <template #header>
      <TitleRegular
        class="text-center"
        :title="$t('shared.mfa.verify_title')"
      />
    </template>
    <template #body>
      <div class="flex justify-center my-8">
        <PincodeInput
          v-if="showVerifyMfaModal && verifyWithOtp"
          id="mfa_pincode"
          :value="otp"
          :length="6"
          :secure="true"
          @input="handleOtp"
        />
        <InputField
          v-else
          id="mfa_code_inout"
          :autofocus="true"
          class="w-full"
          @inputChange="handleCode"
        />
      </div>
    </template>
    <template #footer>
      <BtnsWrapper class="flex flex-row justify-end">
        <BtnSecondary
          :title="btnLabel"
          @click="toggleAuthMethod"
          data-cy="toggle_auth_metod"
        />
        <BtnPrimary
          :title="$t('shared.button.verify')"
          data-cy="verify"
          :disabled="disableBtn"
          @click="handleVerify"
        />
      </BtnsWrapper>
    </template>
  </Modal>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import InputField from '@/components/shared/input/InputField'
import PincodeInput from '@/components/shared/input/PincodeInput'
import Modal from '@/components/shared/Modal'
import i18n from '@/i18n'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const otp = ref('')
const recovery_code = ref('')
const verifyWithOtp = ref(true)
const disableBtn = ref(false)
const showVerifyMfaModal = computed(() => store.state.modals.showVerifyMfaModal)
const btnLabel = computed(() => {
  return verifyWithOtp.value
    ? $t('shared.mfa.recovery_code.btn')
    : $t('shared.mfa.authenticator.btn')
})

const verify2fa = (payload) => store.dispatch('workspace/verify2fa', payload)

function toggleAuthMethod() {
  verifyWithOtp.value = !verifyWithOtp.value
}
function handleCode(payload) {
  recovery_code.value = payload.value
}
async function handleVerify() {
  // action to verify mfa
  disableBtn.value = true
  try {
    const payload = verifyWithOtp.value
      ? { otp: otp.value }
      : { recovery_code: recovery_code.value }
    await verify2fa(payload)
  } finally {
    disableBtn.value = false
  }
}
function handleOtp(value) {
  otp.value = value
}

watch(
  () => otp.value,
  (newCode) => {
    if (newCode.length === 6) {
      handleVerify()
    }
  }
)

watch(
  () => showVerifyMfaModal.value,
  (show) => {
    if (!show) {
      otp.value = ''
      recovery_code.value = ''
      verifyWithOtp.value = true
      disableBtn.value = false
    }
  }
)
</script>
