<template>
  <Modal
    class="[&>.modal-wrapper]:!max-w-[600px] desktop:[&>.modal-wrapper]:!max-w-[1150px]"
    :show-modal="showImportTemplateModal"
    @close="toggleImportTemplateModal"
  >
    <template #header>
      <TitleRegular
        class="text-center"
        :title="$t('customer.sub_nav.caas.templates')"
      />
      <TextSmall class="text-center">
        {{ $t('shared.content_generation.manage_templates') }}
      </TextSmall>
    </template>
    <template #body>
      <div class="bg-etBlack-10 p-4 pt-0 rounded">
        <div
          class="grid grid-cols-[1fr] grid-rows-[repeat(auto-fill,_minmax(max-content,_0))] !h-full pb-4 overflow-auto relative w-full"
        >
          <CaasTableHeader :templates-table="true" />
          <CaasTableRow
            v-for="(temp, index) in templates"
            :key="index"
            :templates-table="true"
            :row-items="temp"
            :allow-edit-template="false"
            :allow-export-template="false"
            @editTemplate="applyTemp"
          >
            <template #applyTemplateBtn>
              <div
                data-cy="apply_template"
                class="cursor-pointer border border-etBlue-primary rounded-sm p-4 w-max mr-3 desktop:mr-0 desktop:py-2 desktop:px-3 hover:bg-etBlue-primary [&>*]:hover:!text-white"
                @click="applyTemp(temp)"
              >
                <TextTiny blue>
                  {{ $t('shared.content_generation.apply_template') }}
                </TextTiny>
              </div>
            </template>
          </CaasTableRow>
        </div>
        <PaginationPerPage
          v-if="pages.current"
          :per-page="pages.perPage"
          :current-page="pages.current"
          :total-pages="pages.total"
          @onChange="onPageChange"
          @changePerPage="onPerPageChange"
        />
      </div>
    </template>
    <template #footer>
      <BtnsWrapperVertical>
        <BtnSecondary
          :title="$t('shared.button.cancel')"
          type="button"
          @click="toggleImportTemplateModal"
        />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import CaasTableHeader from '@/components/contentGeneration/CaasTable/CaasTableHeader'
import CaasTableRow from '@/components/contentGeneration/CaasTable/CaasTableRow'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import Modal from '@/components/shared/Modal'
import PaginationPerPage from '@/components/shared/pagination/PaginationPerPage'
import i18n from '@/i18n'
import { computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const router = useRouter()
const store = useStore()
const $t = i18n.t

const templates = computed(() => store.state.contentGeneration.templates)
const pages = computed(() => store.state.contentGeneration.templatesPages)
const currentTemplate = computed(
  () => store.state.contentGeneration.currentTemplate
)
const showImportTemplateModal = computed(
  () => store.state.modals.showImportTemplateModal
)

const startLoader = () => store.dispatch('loading/startLoader')
const stopLoader = () => store.dispatch('loading/stopLoader')
const getTemplates = (payload) =>
  store.dispatch('contentGeneration/getTemplates', payload)
const getTemplate = (payload) =>
  store.dispatch('contentGeneration/getTemplate', payload)
const toggleImportTemplateModal = () =>
  store.commit('modals/toggleImportTemplateModal')
const applyTemplate = (payload) =>
  store.commit('contentGeneration/applyTemplate', payload)

function onPageChange(pageNum) {
  router
    .push({
      name: 'contentGenerationV2',
      query: {
        page: pageNum,
        perPage: route.query.perPage
      }
    })
    .catch(() => {})
}

function onPerPageChange(perPage) {
  router
    .push({
      name: 'contentGenerationV2',
      query: { page: 1, perPage: perPage }
    })
    .catch(() => {})
}

watch(
  () => route.query,
  async () => {
    if (
      route.meta.baseRoute === 'contentGenerationV2' &&
      showImportTemplateModal.value
    ) {
      await getTemplates({
        currentPage: route.query.page,
        perPage: route.query.perPage
      })
    }
  },
  { deep: true }
)

async function applyTemp(temp) {
  toggleImportTemplateModal()
  startLoader()
  await getTemplate(temp.id)
  applyTemplate(currentTemplate.value)
  stopLoader()
}
</script>
