<template>
  <div
    class="profilePhoto h-10 w-10 rounded-md bg-center bg-cover bg-no-repeat"
    :style="{
      backgroundImage: `url(${hasUrl ? url : require('@/assets/avatar.svg')})`
    }"
  />
</template>
<script setup>
import { computed } from 'vue'
const props = defineProps({
  url: {
    default: null,
    value: String || null
  }
})

const hasUrl = computed(() => !!props.url)
</script>
