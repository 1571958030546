<template>
  <Modal
    :show-modal="showMultilingualProjectModal"
    @close="toggleMultilingualProjectModal"
  >
    <template #body>
      <TextRegular>
        {{ $t('customer.multilingual_project.message') }}
      </TextRegular>
    </template>
  </Modal>
</template>
<script setup>
import TextRegular from '@/components/shared/font/text/TextRegular'
import Modal from '@/components/shared/Modal'
import i18n from '@/i18n'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const showMultilingualProjectModal = computed(
  () => store.state.modals.showMultilingualProjectModal
)
const toggleMultilingualProjectModal = () =>
  store.commit('modals/toggleMultilingualProjectModal')
</script>
