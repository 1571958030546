<template>
  <div class="w-screen h-screen flex justify-center items-center">
    <div
      class="rounded-lg shadow-md flex flex-col flex-wrap w-full h-screen m-0 tabletLandscape:flex-row flex-nowrap desktopHighResolution:w-9/12 desktopHighResolution:h-4/5"
    >
      <slot />
    </div>
  </div>
</template>
<script setup></script>
