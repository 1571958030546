<template>
  <!-- Credits to https://github.com/Seokky and https://github.com/lauraschlimmer for
        https://github.com/Seokky/vue-pincode-input -->
  <div class="inline-flex">
    <input
      v-for="(cell, index) in cells"
      :data-cy="`${id}_${index}`"
      :key="cell.key"
      :ref="
        (el) => {
          refs[`${baseRefName}${index}`] = el
        }
      "
      v-model.trim="cell.value"
      v-bind="$attrs"
      class="outline-none m-[3px] p-[5px] max-w-[40px] text-center text-lg leading-none border border-transparent rounded-[3px] shadow !w-[40px] !h-[50px] !mr-4 focus:border-etBlue-80"
      :type="cellsInputTypes[index]"
      @focus="focusedCellIdx = index"
      @keydown.delete="onCellErase(index, $event)"
      @keydown="onKeyDown"
    />
  </div>
</template>
<script setup>
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'

const BASE_REF_NAME = 'vue-pincode-input'
const CELL_REGEXP = '^\\d{1}$'
const DEFAULT_INPUT_TYPE = 'tel'
const SECURE_INPUT_TYPE = 'password'

const emit = defineEmits(['input'])
const props = defineProps({
  value: {
    type: String,
    required: true
  },
  length: {
    type: Number,
    default: 4
  },
  autofocus: {
    type: Boolean,
    default: true
  },
  secure: {
    type: Boolean,
    default: false
  },
  characterPreview: {
    type: Boolean,
    default: true
  },
  charPreviewDuration: {
    type: Number,
    default: 300
  },
  id: String
})

const baseRefName = ref(BASE_REF_NAME)
const refs = ref({})
const focusedCellIdx = ref(0)
const cells = ref([])
const watchers = ref({})
const cellsInputTypes = ref({})

const pinCodeComputed = computed(() => {
  return cells.value.reduce((pin, cell) => pin + cell.value, '')
})

watch(
  () => props.value,
  (value) => {
    if (value) {
      onParentValueUpdated()
    } else {
      init()
    }
  }
)
watch(
  () => props.length,
  () => {
    init()
  }
)
watch(
  () => pinCodeComputed.value,
  (val) => {
    emit('input', val)
  }
)

onBeforeUnmount(() => {
  baseRefName.value = BASE_REF_NAME
  refs.value = {}
  focusedCellIdx.value = 0
  cells.value = []
  watchers.value = {}
  cellsInputTypes.value = {}
})
onMounted(() => {
  init()
  onParentValueUpdated()
  if (props.autofocus) {
    nextTick(focusCellByIndex)
  }
})

/* init stuff */
function init() {
  unwatchCells()
  const inputType = getRelevantInputType()
  for (let key = 0; key < props.length; key += 1) {
    setCellObject(key)
    setCellInputType(key, inputType)
    setCellWatcher(key)
  }
}
function setCellObject(key) {
  cells.value[key] = { key, value: '' }
}
function setCellInputType(index, inputType = SECURE_INPUT_TYPE) {
  cellsInputTypes.value[index] = inputType
}
function setCellWatcher(index) {
  const watchingProperty = `cells.${index}.value`
  watchers.value[watchingProperty] = watch(
    () => cells?.value[index]?.value,
    (newVal, oldVal) => onCellChanged(index, newVal, oldVal)
  )
}
/* handlers */
function onParentValueUpdated() {
  if (props.value.length !== props.length) {
    emit('input', pinCodeComputed.value)
    return
  }
  props.value.split('').forEach((cell, idx) => {
    cells.value[idx].value = cell || ''
  })
}
function onCellChanged(index, newVal, oldVal) {
  if (!isTheCellValid(newVal, false)) {
    cells.value[index].value = ''
    return
  }
  focusNextCell()
  /* performing character preview if it's enabled */
  if (props.secure && props.characterPreview) {
    setTimeout(setCellInputType, props.charPreviewDuration, index)
  }
}
function onCellErase(index, e) {
  const isThisCellFilled = cells.value[index].value.length
  if (!isThisCellFilled) {
    focusPreviousCell()
    e.preventDefault()
  }
}
function onKeyDown(e) {
  switch (e.keyCode) {
    /* left arrow key */
    case 37:
      focusPreviousCell()
      break
    /* right arrow key */
    case 39:
      focusNextCell()
      break
    default:
      break
  }
}
function unwatchCells() {
  const newWatchers = Object.keys(watchers.value)
  newWatchers.forEach((name) => watchers.value[name]())
}

/* helpers */

function isTheCellValid(cell, allowEmpty = true) {
  if (!cell) {
    return allowEmpty ? cell === '' : false
  }
  return !!cell.match(CELL_REGEXP)
}

function getRelevantInputType() {
  return props.secure && !props.characterPreview
    ? SECURE_INPUT_TYPE
    : DEFAULT_INPUT_TYPE
}

function focusPreviousCell() {
  if (!focusedCellIdx.value) return
  focusCellByIndex(focusedCellIdx.value - 1)
}

function focusNextCell() {
  if (focusedCellIdx.value === props.length - 1) return
  focusCellByIndex(focusedCellIdx.value + 1)
}

function focusCellByIndex(index = 0) {
  const ref = `${baseRefName.value}${index}`
  if (refs.value[ref]) {
    const el = refs.value[ref]
    el.focus()
    el.select()
  }
  focusedCellIdx.value = index
}
</script>
